.Container {
  text-align: center;
  padding: 0.75rem;
  width: 100%;
  max-width: 1140px;
  margin-right: auto;
  margin-left: auto;
}

.Container ul {
  display: grid;
  grid-template-columns: max-content max-content max-content max-content max-content max-content max-content max-content max-content max-content;
  justify-content: center;
  margin: 0;
  padding: 0;
}

.Container ul li {
  list-style: none;
  margin: 0;
}

.Container ul li button {
  cursor: pointer;
  display: block;
  padding: 0.75rem 1rem;
  text-align: center;
  background: none;
  border: none;
  font-size: 16px;
}

.Container ul:first-child {
  border-left: none;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 500;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

@media screen and (min-width: 768px) {
  .SideMenu {
    top: calc(74px + 3.5rem);
  }
}

.SideMenu {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  top: calc(54px + 3.5rem);
  z-index: 550;


  width: 100%;
}

.displayNone {
  display: none;
}

.SideMenu ul {
  display: grid;
  grid-template-columns: max-width;
  margin: 0;
  padding: 0.25rem 1rem;
}

.SideMenu ul li {
  list-style: none;
  margin: 0;
}

.SideMenu ul li button {
  cursor: pointer;
  display: block;
  padding: 0.75rem 0;
  text-align: center;
  background: none;
  border: none;
  font-size: 16px;
  width: 100%;
}

.SideMenu ul:first-child {
  border-top: none;
}